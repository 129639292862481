import React, {Component} from 'react';
import Content from "../common/containers/Content";
import PrivatePage from "../common/containers/PrivatePage";
import {withNamespaces} from "react-i18next";
import Invites from "./Invites";
import Row from "../common/containers/Row";
import Col from "../common/containers/Col";
import Discounts from "./Discounts";

class InvitingPage extends Component {

    render() {
        const {i18n} = this.props;
        return (
            <PrivatePage
                title={i18n.t('Convidar amigos')}
                hasAside={false}
            >
                <Content>
                    <Row>
                        <Col md={8}>
                            <Invites/>
                        </Col>
                        <Col md={4}>
                            <Discounts/>
                        </Col>
                    </Row>
                </Content>
            </PrivatePage>
        );
    }
}

export default withNamespaces()(InvitingPage);
