import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";
import Panel from "../common/containers/Panel";
import LocaleUtils from "../common/LocaleUtils";
import Config from "../config";
import Ajax from "../common/ajax";

class Discounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            discounts: [],
        };
    }

    componentDidMount() {
        this.fetchDiscounts()
    }

    fetchDiscounts() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}discounts/`;
        Ajax.get(url).done(discounts => {
            this.setState(state => ({
                ...state,
                discounts,
                processing: true
            }));
        });
    }

    render() {
        const {i18n} = this.props;
        return (
            <Panel
                title={i18n.t('Cupons de desconto disponíveis')}
                isProcessing={this.state.processing.length > 0}
            >
                {this.state.discounts.length > 0 &&
                <table className="table">
                    <thead>
                        <tr>
                            <th>{i18n.t('Código')}</th>
                            <th>{i18n.t('Validade')}</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.discounts.map(d => {
                        return (
                            <tr key={d.id}>
                                <td>{d.code}</td>
                                <td>{LocaleUtils.calendar(d.end_date)}</td>
                                <td>{d.percentage * 100}%</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>}

                {this.state.discounts.length === 0 &&
                    <p>{i18n.t('Sem cupons de desconto.')}</p>}
            </Panel>
        );
    }
}

export default withNamespaces()(Discounts);