import React, {Component} from 'react';
import {withNamespaces} from "react-i18next";
import Row from "../common/containers/Row";
import Col from "../common/containers/Col";
import ModalAlert from "../common/components/ModalAlert";
import Input from "../common/components/form/Input";
import Button from "../common/components/Button";
import Panel from "../common/containers/Panel";
import Config from "../config";
import Ajax from "../common/ajax";

class Invites extends Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.fetchInvitations = this.fetchInvitations.bind(this);
        this.fetchRemainingInvitations = this.fetchRemainingInvitations.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.verifyAlreadyInvited = this.verifyAlreadyInvited.bind(this);
        this.state = {
            processing: [],
            invitations: [],
            remainingInvitations: 0,
            emails: ['', '', '', '', ''],
            errorMsg: '',
            successMsg: ''
        }
    }

    componentDidMount() {
        this.fetchInvitations();
        this.fetchRemainingInvitations();
    }

    fetchInvitations() {
        this.setState(s => ({
            ...s,
            processing: s.processing.concat(1)
        }));
        const url = `${Config.apiHost}inviting/`;
        Ajax.get(url).done(invitations => {
            this.setState(s => ({
                ...s,
                invitations,
                processing: s.processing.slice(1)
            }));
        }).fail(() => {
            this.setState(s => ({...s, processing: s.processing.slice(1)}));
        });
    }

    fetchRemainingInvitations() {
        this.setState(s => ({...s, processing: s.processing.concat(1)}));
        const url = `${Config.apiHost}inviting/remaining_invitations/`;
        Ajax.get(url).done(remaining => {
            this.setState(s => ({
                ...s,
                remainingInvitations: remaining,
                processing: s.processing.slice(1)
            }));
        }).fail(() => {
            this.setState(s => ({...s, processing: s.processing.slice(1)}));
        });
    }

    onEmailChange(e, idx) {
        const value = e.target.value;

        const emails = this.state.emails.map((email, stateIdx) => {
            if (idx !== stateIdx) {
                return email;
            } else {
                return value;
            }
        });

        this.setState(s => ({
            ...s,
            emails,
        }));
    }

    verifyAlreadyInvited(e) {
        const {i18n} = this.props;
        const value = e.target.value;

        const isInvited = this.state.invitations.find(i => i.email === value);
        this.setState(state => ({
            ...state,
            errorMsg: isInvited ? `${i18n.t('Já existe um convite para o e-mail')} "${value}".` : '',
        }));
    }

    submitForm(e) {
        const {i18n} = this.props;
        e.preventDefault();
        this.setState(s => ({
            ...s,
            processing: s.processing.concat(1),
            successMsg: '',
            errorMsg: '',
        }));
        const url = `${Config.apiHost}inviting/invite_many/`;
        Ajax.post(url, {emails: this.state.emails}).done((response) => {
            this.setState(s => ({
                ...s,
                processing: s.processing.slice(1),
                successMsg: response.detail,
                emails: ['', '', '', '', ''],
            }));
            this.fetchInvitations();
            this.fetchRemainingInvitations();
        }).fail((jqXHR) => {
            console.log(jqXHR);
            this.setState(s => ({
                ...s,
                processing: s.processing.slice(1),
                errorMsg: i18n.t('Não foi possível enviar os convites.'),
            }));
        });
    }

    render() {
        const {i18n} = this.props;
        return (
            <Panel title={i18n.t('Ao convidar seus amigos você pode ganhar e acumular recompensas')}
                   subtitle={i18n.t('A cada 5 convites aceitos você ganhará um desconto de 20%.')}
                   isProcessing={this.state.processing.length > 0}>
                <Row>
                    <Col md={6}>
                        <p>{i18n.t('Informe os e-mails nos campos abaixo e clique em Convidar.')}</p>

                        <ModalAlert show={!!this.state.errorMsg}
                                    type="danger"
                                    onClose={() => this.setState(s => ({...s, errorMsg: null}))}>
                            <h3>{this.state.errorMsg}</h3>
                        </ModalAlert>

                        <ModalAlert show={!!this.state.successMsg}
                                    type="success"
                                    onClose={() => this.setState(s => ({...s, successMsg: null}))}>
                            <h3>{this.state.successMsg}</h3>
                        </ModalAlert>

                        <form onSubmit={this.submitForm}>
                            {this.state.emails.map((email, idx) =>
                                <Input key={idx}
                                       placeholder="E-mail"
                                       name={`email_${idx}`}
                                       type="email"
                                       onChange={(e) => this.onEmailChange(e, idx)}
                                       onBlur={this.verifyAlreadyInvited}
                                       value={this.state.emails[idx]}/>)}
                            <Button submit={true} size="lg">
                                {i18n.t('Convidar')}
                            </Button>
                        </form>
                    </Col>
                    <Col md={6}>
                        <p className="text-muted">{i18n.t('Convites enviados')}: {this.state.invitations.length} | {i18n.t('Convites restantes')}: {this.state.remainingInvitations}</p>
                        {this.state.invitations.length > 0 &&
                        <table className="table">
                            <thead>
                            <tr>
                                <th>E-mail</th>
                                <th width="1%">{i18n.t('Aceito')}?</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.invitations.map(i => {
                                return (
                                    <tr key={i.id}>
                                        <td>{i.email}</td>
                                        <td>{i.accepted ? i18n.t('Sim'): i18n.t('Não')}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>}
                    </Col>
                </Row>
            </Panel>
        );
    }
}

export default withNamespaces()(Invites);